import router from '@/router';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        pagination: {
            data: []
        },
        all: [],
        period: null,
        loading: false,
        specializations: []
    },
    mutations: {
        SET_PAGINATION: (state, pagination) => state.pagination = pagination,
        SET_PERIOD: (state, period) => state.period = period,
        SET_PERIODS: (state, sections) => state.all = sections,
        SET_PENSUMS: (state, period) => state.pensums = period,
        TOGGLE_LOADING: state => state.loading = !state.loading,
        SET_PERIOD_INSCRIPTIONS: (state, status) => state.period = {...state.period, ...status},
        SET_SPECIALIZATIONS: (state, specializations) => state.specializations = specializations
    },
    actions: {
        index({ commit }, query = {}) {
            commit('TOGGLE_LOADING')
            return this.$repository.periods
                .index({order_by: 'id,desc', ...query})
                .then(({ data }) => {
                    commit(query?.limit === 0 ? 'SET_PERIODS' : 'SET_PAGINATION', data)
                    return data
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        show({ commit }, { id, query = {} }) {
            commit('TOGGLE_LOADING')
            return this.$repository.periods
                .show(id, query)
                .then(({ data }) => {
                    commit('SET_PERIOD', data)
                    return data
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        enabledWithPensums({ commit }, { filters = {} }) {
            commit('TOGGLE_LOADING')
            return this.$repository.periods
                .enabledWithPensums(filters)
                .then(({ data }) => {
                    commit('SET_PENSUMS', data)
                }).finally(() => commit('TOGGLE_LOADING'))
        },
        fetchCoursesBySpecialization({ commit }, { id, filters = {} }) {
            commit('TOGGLE_LOADING')
            this.$repository.periods
                .coursesBySpecialization(id, filters)
                .then(res => commit('SET_SPECIALIZATIONS', res.data.specializations))
                .finally(() => commit('TOGGLE_LOADING'))
        },
        store({ commit }, period) {
            commit('TOGGLE_LOADING')
            this.$repository.periods
                .store({
                    ...period,
                    semester_price_e2: (period.semester_price_e2 ?? 0) * 100
                })
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el período con éxito`,
                        
                    })
                    router.push({ name: 'periods' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        update({ commit }, period) {
            commit('TOGGLE_LOADING')
            this.$repository.periods
                .update(period.id, {
                    ...period,
                    semester_price_e2: (period.semester_price_e2 ?? 0) * 100
                })
                .then(() => {
                    Vue.prototype.$message.success({
                        message: `Se ha creado el período con éxito`,
                    })
                    router.push({ name: 'periods' })
                })
                .finally(() => commit('TOGGLE_LOADING'))
        },
        delete({ commit, state }, id) {
            Vue.prototype.$confirm({
                message: `¿Seguro que desea eliminar este período?`,
                button: {
                    no: 'No',
                    yes: 'Si'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$repository.periods
                            .delete(id)
                            .then(() => {
                                const index = state.pagination.data.findIndex(period => period.id === id)
                                commit('DROP_PERIOD', index)
                            })
                    }
                }
            })
        },
        openInscriptions(_, period) {
            return this.$repository.periods
                .openInscriptions(period.id, {
                    enabled_inscriptions: period.enabled_inscriptions,
                    enabled_preregisters: period.enabled_preregisters
                })
                .then((e) => {
                    return e
                    // dispatch('fetchPeriods', router.currentRoute.query)
                })
        },
        openInscriptionsFromProfile({ state, commit }, period) {
            this.$repository.periods
                .openInscriptions(period.id, {
                    enabled_inscriptions: period.enabled_inscriptions,
                    enabled_preregisters: period.enabled_preregisters
                })
                .then(() => {
                    Vue.prototype.$message.success({
                        message: 'Su actualización ha sido exitosa',
                        position: 'top-right'
                    })
                })
                .catch(() => {
                    commit('SET_PERIOD_INSCRIPTIONS', {
                        enabled_inscriptions: state.period.enabled_inscriptions !== period.enabled_inscriptions ? !period.enabled_inscriptions : period.enabled_inscriptions,
                        enabled_preregisters: state.period.enabled_preregisters !== period.enabled_preregisters ? !period.enabled_preregisters : period.enabled_preregisters
                    })
                })
        }
    }
}
