import Vue from "vue";

export default ({ axios, mapQueryString }) => ({
    index: (query = {}) => axios.get(`/api/document-requests${mapQueryString(query)}`),
    show: (id) => axios.get(`/api/document-requests/${id}`),
    store: (body) => axios.post(`/api/document-requests`, body),
    delete: (id) => axios.delete(`/api/document-requests/${id}`),
    markGenerated: (id, document) => {
        if(document){
            switch(document){
                case 1:
                    return axios.get(`/api/document-requests/${id}/generate-certificate`).then((res) => {
                        axios.patch(`/api/document-requests/${id}/set-generated`);
                        Vue.prototype.$message.success({
                            title: 'Éxito!',
                            message: res.data.message,
                        });
                    }).catch(() => {
                        Vue.prototype.$message.error({
                            title: 'Error',
                            message: "Ocurrió un error al enviar la constancia de estudio."
                        });
                    });
                case 2:
                    return axios.get(`/api/document-requests/${id}/generate-certificateNote`).then((res) => {
                        axios.patch(`/api/document-requests/${id}/set-generated`);
                        Vue.prototype.$message.success({
                            title: 'Éxito!',
                            message: res.data.message,
                        });
                    }).catch(() => {
                        Vue.prototype.$message.error({
                            title: 'Error',
                            message: "Ocurrió un error al enviar el certificado de notas.",
                        });
                    });
                case 4:
                    return axios.get(`/api/document-requests/${id}/generate-letter`).then((res) => {
                        axios.patch(`/api/document-requests/${id}/set-generated`);
                        Vue.prototype.$message.success({
                            title: 'Éxito!',
                            message: res.data.message,
                        });
                    }).catch(() => {
                        Vue.prototype.$message.error({
                            title: 'Error',
                            message: "Ocurrió un error al enviar la carta de recomendación de egresados.",
                        });
                    });
                default:
                    break;
            }
        }
    },
    markRetired: (id) => axios.patch(`/api/document-requests/${id}/set-retired`),
    markCanceled: (id, body) => axios.patch(`/api/document-requests/${id}/set-canceled`, body),
})