<template>
    <a-select
        :label="label"
        class="w-48"
        :clearable="false"
        :value="value"
        :reduce="$0 => $0.value"
        :source="periods"
        @input="$emit('input', $event)"
        :loading="loading"
        :disabled="disabled"
        placeholder="Período"/>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data: () => ({
        periods: []
    }),
    props: {
        value: { requried: false, type: [String, Number], default: null },
        label: { required: false, type: String, default: null },
        disabled: {required: false, type: Boolean, default: false}
    },
    computed: {
    ...mapState({
      loading: state => state.periods.loading
    }),
    },
    methods: {
    ...mapActions({
      fetchPeriods: 'periods/index'
    })
    },
    mounted() {
        this.fetchPeriods({ limit: 0, sort_by: 'year,desc' })
            .then(( data ) => {
                this.periods = data.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
                this.$emit('input', data.find($0 => $0.enabled)?.id)
            })
    }
}
</script>