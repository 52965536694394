export default ({ axios, mapQueryString }) => ({
    index: (filters = {}) => axios.get(`/api/preregistered-students${mapQueryString(filters)}`),
    show: (id) => axios.get(`/api/preregistered-students/${id}`),
    store: (body) => axios.post(`/api/preregistered-students`, body),
    update: (id, body) => axios.patch(`/api/preregistered-students/${id}`, body),
    delete: (id) => axios.delete(`/api/preregistered-students/${id}`),
    confirm: (id) => axios.post(`/api/preregistered-students/${id}/confirm`),
    pay: (inscription, body, configuration = {}) => axios.post(`/api/inscriptions/${inscription}/payments`, body, configuration),
    inscript: (body) => axios.post(`/api/inscriptions/`, body),
    rollback: (body) => axios.post(`/api/inscriptions/rollback`, body),
    updateSpecialization: (body) => axios.post(`/api/inscriptions/update`, body),
    reInscription: (body) => axios.post(`/api/inscriptions/update/reinscription`, body)
})